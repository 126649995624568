





























import { defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { userStore } from '@/store/user.store';
import TrackingUtils from '@/utils/tracking/tracking.utils';
import { scrollTo } from '@/utils/ui.utils';
import webAppUtils from '@/utils/webApp.utils';
import videoPlayer from './videoPlayer.component.vue';
import NexxSDKLoader from '../../utils/nexxSDKLoader.utils';

export default defineComponent({
  name: 'video-app-component',
  components: {
    videoPlayer,
  },
  props: {
    hideButton: {
      type: String,
      default: 'false',
    },
    isPremium: {
      type: String,
      default: 'false',
    },
    videoDomainId: {
      type: String,
      required: true,
    },
    videoId: {
      type: String,
      required: true,
    },
    autoShowVideoLayer: {
      type: Boolean,
      default: false,
    },
    largeButton: {
      type: String,
      default: 'false',
    },
    externalTrigger: {
      type: String,
      default: '',
    },
  },
  setup(props, ctx) {
    const showVideoLayer = ref(false);

    const startVideo = async () => {
      document.body.classList.add('video-visible');
      if (props.isPremium === 'true' && !userStore.loggedIn) {
        scrollTo('#registration-blocker');
      } else {
        document.body.style.overflow = 'hidden';
        // eslint-disable-next-line no-underscore-dangle
        if (typeof window._play === 'object' && window._play.control.sdkIsReady()) {
          showVideoLayer.value = true;
          TrackingUtils.trackEvent({
            event: 'b_video_open',
            eventCategory: 'video',
            eventAction: 'video.overlay.open',
            eventLabel: window.analyticsData.b_articleName || '(not set)',
            videoPublishingdate: window.analyticsData.b_articlePublishingdate || '(not set)',
            videoPlayerType: 'overlay',
          });
        } else {
          window.addEventListener('nexxplay.ready', () => startVideo());
          if (!window.nexxSDKLoaded) {
            window.nexxSDKLoaded = true;
            await NexxSDKLoader.initVideoScripts(parseInt(props.videoDomainId));
          }
        }
      }
    };
    const closeVideo = () => {
      ctx.emit('video-closed');
      document.body.classList.remove('video-visible');
      document.body.style.overflow = '';
      showVideoLayer.value = false;
    };

    watch(showVideoLayer, (isLayerOpen) => {
      webAppUtils.sendToWebapp({
        isModalOpen: isLayerOpen,
      });
    });
    onMounted(() => {
      if (props.autoShowVideoLayer) {
        startVideo();
      }
      if (props.externalTrigger) {
        const externalTrigger = document.getElementById(props.externalTrigger);
        if (!externalTrigger) return;
        externalTrigger.addEventListener('click', startVideo);
      }
    });

    return {
      showVideoLayer,
      startVideo,
      closeVideo,
    };
  },
});
