// 584 is production domain id, set due to lack of data in DB
const initVideoScripts = (domainId = 584): Promise<void> =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.setAttribute('src', `https://arc.nexx.cloud/sdk/${domainId}.play`);
    // Resolve on nexx player Ready function
    window.onPlayReady = () => {
      console.log('Nexx SDK Ready');
      resolve();
    };
    script.onerror = reject;
    document.body.appendChild(script);
  });

export default {
  initVideoScripts,
};
