































import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';
import { defineComponent } from '@vue/composition-api';
import relatedVideoItem from './relatedVideoItem.component.vue';

export default defineComponent({
  name: 'related-videos',
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    relatedVideoItem,
  },
  props: {
    relatedVideos: {
      type: Array,
      required: true,
    },
    isDisabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      hooperSettings: {
        itemsToShow: 2.3,
        infiniteScroll: true,
        breakpoints: {
          768: {
            itemsToShow: 2.3,
          },
          995: {
            itemsToShow: 3.3,
          },
          1400: {
            itemsToShow: 4.3,
          },
          2000: {
            itemsToShow: 5.3,
          },
        },
      },
    };
  },
  methods: {
    playRelatedVideo(videoId: string) {
      if (!this.isDisabled) {
        this.$emit('play-related', videoId);
      }
    },
  },
});
