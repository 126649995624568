export const videos = {
  numberOfRelated: 20,
};

export const player = (isWebApp: boolean) => ({
  dataMode: 'static',
  autoPlay: 1,
  autoPlayIfMutedPossible: 0,
  exitMode: 'load',
  autoNext: 1,
  mobileAutoFullscreen: 1,
  webViewRestrictionsDisabled: isWebApp ? 1 : 0,
});

export const videoEventMappings = {
  adclicked: { event: 'b_video_ad_click', action: 'ad.click' },
  adended: { event: 'b_video_ad_end', action: 'ad.end' },
  adstarted: { event: 'b_video_ad_start', action: 'ad.start' },
  ended: { event: 'b_video_content_end', action: 'content.end' },
  progress25: {
    event: 'b_video_content_progress_25',
    action: 'content.progress.25',
  },
  progress50: {
    event: 'b_video_content_progress_50',
    action: 'content.progress.50',
  },
  progress75: {
    event: 'b_video_content_progress_75',
    action: 'content.progress.75',
  },
  startplayback: { event: 'b_video_start', action: 'video.start' },
  error: { event: 'b_video_error', action: 'video.error' },
  startplay: { event: 'b_video_content_start', action: 'content.start' },
};

export const xymaticVideoEventMappings = {
  'xym-ad-clicked': { event: 'b_video_ad_click', action: 'ad.click' },
  'xym-ad-end': { event: 'b_video_ad_end', action: 'ad.end' },
  'xym-ad-finish-showing': { event: 'b_video_ad_end', action: 'ad.end' },
  'xym-ad-start': { event: 'b_video_ad_start', action: 'ad.start' },
  'xym-milestone5': { event: 'b_video_content_start', action: 'content.start' },
  'xym-milestone5-muted': { event: 'b_video_content_start', action: 'content.start' },
  'xym-milestone25': { event: 'b_video_content_progress_25', action: 'content.progress.25' },
  'xym-milestone25-muted': { event: 'b_video_content_progress_25', action: 'content.progress.25' },
  'xym-milestone50': { event: 'b_video_content_progress_50', action: 'content.progress.50' },
  'xym-milestone50-muted': { event: 'b_video_content_progress_50', action: 'content.progress.50' },
  'xym-milestone75': { event: 'b_video_content_progress_75', action: 'content.progress.75' },
  'xym-milestone75-muted': { event: 'b_video_content_progress_75', action: 'content.progress.75' },
  'xym-milestone100': { event: 'b_video_content_end', action: 'content.end' },
  'xym-milestone100-muted': { event: 'b_video_content_end', action: 'content.end' },
  'xym-error': { event: 'b_video_error', action: 'video.error' },
  'xym-content-start': { event: 'b_video_start', action: 'video.start' },
  'xym-recommendation-play': { event: 'b_video_teaser_click', action: 'video.teaser.click' },
};
