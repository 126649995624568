






























import { computed, defineComponent, onBeforeUnmount, onMounted, ref, watch } from '@vue/composition-api';
import { player as playerConfig, videoEventMappings, videos as videosConfig } from '@/config/video';
import { userStore } from '@/store/user.store';
import { globalStore } from '@/store/global.store';
import TrackingUtils, { ITrackingEvent } from '@/utils/tracking/tracking.utils';
import { initBFAVideoAds } from '@/utils/ads/BFAAds';
import CommonUtils from '@/utils/common.utils';
import client from '../../api/s3Client.api';
import * as ivw from '../../utils/ivw.utils';
import relatedVideosComponent from './relatedVideos.component.vue';

/* eslint-disable no-underscore-dangle */
const webAppPlayerComposite = (closeFnc: () => any) => {
  const onWebAppBackButtonPressed = () => {
    if (document.fullscreenElement !== null) {
      // eslint-disable-next-line no-underscore-dangle
      window._play.control.interact.exitFullscreen();
      (document.fullscreenElement as any).exitFullscreen();
    } else {
      closeFnc();
    }
  };
  const onWebAppRequestFullScreen = () => {
    // eslint-disable-next-line no-underscore-dangle
    window._play.control.interact.enterFullscreen();
    const element = document.getElementById('video-player');
    if (element) element.requestFullscreen();
  };

  onMounted(() => {
    window.addEventListener('webapp-backbutton-pressed', onWebAppBackButtonPressed);
    window.addEventListener('webapp-request-fullscreen', onWebAppRequestFullScreen);
  });
  onBeforeUnmount(() => {
    window.removeEventListener('webapp-backbutton-pressed', onWebAppBackButtonPressed);
    window.removeEventListener('webapp-request-fullscreen', onWebAppRequestFullScreen);
  });

  return {
    isWebApp: globalStore.isWebapp,
    onWebAppBackButtonPressed,
    onWebAppRequestFullScreen,
  };
};

export default defineComponent({
  name: 'video-player',
  components: {
    relatedVideos: relatedVideosComponent,
  },
  props: {
    videoId: {
      type: String,
      required: true,
    },
    domainId: {
      type: String,
      default: '',
    },
    isPremium: {
      type: Boolean,
    },
  },
  setup(props, ctx) {
    const playerDiv = ref('video-player');
    const isDisabled = ref(false);
    const videos = ref<any[]>([]);
    const activeVideoId = ref<null | number>(Number(props.videoId));

    const activeVideo = computed(() => videos.value.find((video) => video.id.toString() === activeVideoId.value?.toString()));
    const relatedVideos = computed(() => videos.value.filter((video) => video.id.toString() !== activeVideoId.value?.toString()));

    const closeVideoLayer = () => ctx.emit('closeVideo', new Event('close'));
    const { isWebApp } = webAppPlayerComposite(closeVideoLayer);

    const fetchRelatedVideos = async () => {
      try {
        const { data } = await client.getRelated(parseInt(props.videoId, 10));
        /* in case nexx is down or any other error http200 is returned to be sucessfully cached
         and origin is not hit that hard. Return is status = 404 + message */
        if (data.status && data.status === 404) {
          console.warn(`Nexx loading related videos failed, error: ${data.message}`);
          videos.value = [];
        } else {
          videos.value = data.slice(0, videosConfig.numberOfRelated);
        }
      } catch (err) {
        console.log(err);
      }
    };

    const dataLayer = (currentEvent: ITrackingEvent) => {
      TrackingUtils.trackEvent({
        event: currentEvent.event,
        eventCategory: 'video',
        eventAction: currentEvent.eventAction || currentEvent.action,
        eventLabel: `${activeVideo.value.id}: ${activeVideo.value.kicker || ''}: ${activeVideo.value.title || ''}`,
        videoPlayerType: 'overlay',
        revolverplay: currentEvent.revolverplay,
      });
    };
    const changePlayer = (videoId: number) => {
      activeVideoId.value = videoId;
      window._play.control.interact.swapToMediaItem(playerDiv.value, activeVideoId.value, 'video', 0, 0);
      dataLayer({
        event: 'b_video_teaser_click',
        eventAction: 'video.teaser.click',
      });
      const image = document.getElementsByClassName('behavewidth')[0] as HTMLAnchorElement;
      if (image) {
        image.style.display = 'none';
      }
    };
    const playRelatedVideo = (videoId: number) => {
      // Move current video to the end of list
      videos.value = [...relatedVideos.value, activeVideo.value];
      changePlayer(videoId);
    };

    const initPlayer = () => {
      const videoConfig = new window._play.PlayerConfiguration({
        ...playerConfig(isWebApp),
        disableAds: false,
        playstateListeners: [
          (event: any) => {
            if (event.event === 'metadata') {
              const {
                subtitle: kicker,
                thumb: teaser,
                title,
                runtime: duration,
                ID: id,
              } = window._play.control.getCurrentMedia(playerDiv.value);
              if (!videos.value.find((video) => video.id === id)) {
                videos.value.push({ title, kicker, teaser, duration, id });
              }
              if (id && activeVideoId.value !== id) {
                activeVideoId.value = id;
              }
            }
            const eventName = event.event as keyof typeof videoEventMappings;
            const currentEvent = videoEventMappings[eventName];
            if (currentEvent && currentEvent.event === 'b_video_start') {
              if (event.data.playReason !== 'revolver') {
                ivw.pageView('video');
              }
              dataLayer({
                eventAction: currentEvent.action,
                event: currentEvent.event,
                ...(event.data.playReason === 'revolver' ? { revolverplay: 'true' } : {}),
              });
            } else if (currentEvent && currentEvent.event === 'b_video_content_progress_75') {
              dataLayer(currentEvent);
            } else if (currentEvent) {
              dataLayer(currentEvent);
            }

            if (event.event === 'adstarted' || window._play.control.instanceIsReady(playerDiv.value) === false) {
              isDisabled.value = true;
            }
            if (event.event === 'playerready' || event.event === 'adended' || event.event === 'aderror') {
              isDisabled.value = false;
            }
            if (event.event === 'error') {
              isDisabled.value = false;
            }
          },
        ],
      });
      if (window._play.config.listeners.playstate.length < 1) {
        if (isWebApp) {
          window._play.config.setPlatform(globalStore.appPlatform);
        }
      }
      window._play.config.setUserIsTrackingOptOuted(globalStore.tcString);
      console.log(`CMP: Nexx.tv TC String set ${globalStore.tcString}`);
      window._play.control.addPlayer(playerDiv.value, activeVideoId.value, 'video', videoConfig);
    };

    onMounted(async () => {
      activeVideoId.value = parseInt(props.videoId, 10);
      const videoWrapper = document.querySelector('.fe-video-wrapper');
      if (
        videoWrapper &&
        (!window.analyticsData.hasOwnProperty('b_adsEnabled') ||
          window.analyticsData.b_adsEnabled === 'true' ||
          window.analyticsData.b_adsEnabled === true)
      ) {
        initBFAVideoAds(videoWrapper.parentElement?.clientWidth, videoWrapper.parentElement?.clientHeight);
      }
      console.log('Initializing player with video id:', props.videoId);
      await initPlayer();
      fetchRelatedVideos();
    });
    onBeforeUnmount(() => {
      window._play.control.removePlayer();
    });

    watch(activeVideo, (newVal) => {
      const video = document.querySelector('video');
      if (video && newVal) {
        video.setAttribute('data-io-video-title', CommonUtils.fixedEncodeURIComponent(`${newVal.kicker}: ${newVal.title}`));
        video.setAttribute('data-io-video-id', activeVideoId.value!.toString());
      }
    });
    return {
      videos,
      isDisabled,
      activeVideoId,
      activeVideo,
      relatedVideos,
      playerDiv,
      closeVideoLayer,
      playRelatedVideo,
    };
  },
});
